import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { ClassNameMap } from '@mui/styles/withStyles';
import { PurchaseBriefPromotionOutput } from 'services/api/client';
import { PlanInterface } from 'api/userEnrollInfo';
import { TooltipDialog } from 'containers/Dialog';
import { PromotionBanner } from 'components/PromotionBanner';
import { Plan, PlanListItem, PlanPrice } from 'components/Plan';
import { UseCheckoutDetails } from 'context/checkoutDetails.context';
import { HouseholdMemberBlock } from 'components/HouseholdMembers';
import { TravelProtectionBlock } from 'components/TravelProtectionBlock';
import { useMemberApi } from 'hooks/useMemberApi';
import { MemberInfoOutput, PurchaseBriefPlanOutputTypeEnum } from 'services/api/client';
import InfoIcon from '@mui/icons-material/Info';
import { ensureText, formatDateDiff } from 'utils';

const updatePlans = (plans: PlanInterface[] | undefined, key: keyof PlanInterface, value: string) => {
  if (!plans) return [];
  return plans.map((p) => (p[key] === value ? { ...p, selected: true } : { ...p, selected: false }));
};

const findSelectedPlan = (plan: PlanInterface) => plan.selected;

type Props = {
  plans: PlanInterface[] | undefined;
  promotion: null | PurchaseBriefPromotionOutput;
  classes: ClassNameMap;
  handleOpenMembersDialog: () => void;
  handleOpenTravelProtectionDialog: () => void;
  handleButtonNextStep: () => void;
  handleOpenTrialDialog: () => void;
};

export function SelectPlan({
  plans,
  promotion,
  classes,
  handleOpenMembersDialog,
  handleOpenTravelProtectionDialog,
  handleButtonNextStep,
}: Props): JSX.Element {
  const yearPlan = plans?.find((plan) => plan.type === 'year');
  const { nid: promoId, code: promoCode } = promotion || {};
  const otpPlans = yearPlan?.subscription_extension.filter(({ otp, hidden }) => otp && !hidden);
  !!plans &&
    !promoId &&
    plans.length < 5 &&
    plans?.push(
      ...(otpPlans?.map((product) => ({
        id: product.id,
        title: product.title,
        price: product.price,
        note: product.note,
        one_time_purchase_product: true,
        processing_fee: yearPlan?.processing_fee ?? null,
        period: product.period,
        subscription_extension: [],
        travel_protection: yearPlan?.travel_protection ?? [],
        trial: false,
        type: PurchaseBriefPlanOutputTypeEnum.Year,
        inactive: false,
        parentPlan: yearPlan?.id,
      })) ?? [])
    );
  const [pricingPlans, setPricingPlans] = useState<PlanInterface[]>([]);

  const {
    state: { config, selectedPlan, user },
    dispatch,
  } = UseCheckoutDetails();

  useEffect(() => {
    if (promoId && promoCode) {
      dispatch({
        type: 'SET_PROMO_INFO',
        payload: { promoId, promoCode },
      });
    }
  }, [promoId, promoCode, dispatch]);

  const tpDisabled =
    !selectedPlan?.travel_protection ||
    !selectedPlan?.travel_protection.length ||
    (config.travel_protection_disable && !config.travel_protection_preselect);

  const { data } = useMemberApi<MemberInfoOutput>('memberInfo');
  const [tpRequiredError, setTpRequiredError] = useState(false);
  const otpPurchased = data && ['3year', '5year', 'lifetime'].includes(data.plan_type);
  const otpUpgrade = data && ['year', '3year', '5year', 'lifetime'].includes(data.plan_type);
  const otpSelected = selectedPlan && !!selectedPlan.one_time_purchase_product;
  const [activeFilter, setActiveFilter] = useState(otpSelected ? 2 : 1);

  const showPromotionBlock = !!promotion && pricingPlans.length === 1;
  useEffect(() => {
    if (!plans || !Object.keys(plans).length) return;
    if (!data) return;
    let updPlans: PlanInterface[] = [];
    if (selectedPlan?.id) {
      const plan = plans.find((plan) => selectedPlan.id === plan.id);
      if (plan && plan.selected && (otpUpgrade || otpSelected)) {
        setActiveFilter(2);
      }
      updPlans = updatePlans(plans, 'id', selectedPlan.id);
    } else if (otpUpgrade && !promoId) {
      setActiveFilter(2);
      dispatch({
        type: 'SELECT_PLAN',
        payload: { ...plans.find((item) => !!item.one_time_purchase_product)!, promoCode, promoId },
      });
    } else {
      const payload = updatePlans(plans, 'title', '12 Months').find(findSelectedPlan) as PlanInterface;
      dispatch({ type: 'SELECT_PLAN', payload: { ...(payload || plans[0]), promoCode, promoId } });
    }

    setPricingPlans(updPlans);
  }, [dispatch, selectedPlan?.id, selectedPlan?.travel_protection, data]);

  useEffect(() => {
    if (undefined !== user.travel_protection) {
      setTpRequiredError(false);
    }
  }, [user.travel_protection]);

  useEffect(() => {
    if (selectedPlan?.id) {
      setTpRequiredError(false);
    }
  }, [selectedPlan?.id]);

  const expiresIn = data?.plan_expires && formatDateDiff(data?.plan_expires);
  const handleSelectPlan = (id = '') => {
    const updPlans = updatePlans(plans, 'id', id);
    dispatch({
      type: 'SELECT_PLAN',
      payload: { ...(updPlans.find(findSelectedPlan) as PlanInterface), promoCode, promoId },
    });
    setPricingPlans(updPlans);
  };

  const handleNext = () => {
    if (!tpDisabled && undefined === user.travel_protection) {
      setTpRequiredError(true);
    } else {
      handleButtonNextStep();
    }
  };

  const setPLanView = (promo: null | PurchaseBriefPromotionOutput, type: number) => {
    if (!!promo && pricingPlans.length === 1) return <PromotionBanner promotion={promo} />;
    const sortMap = {
      '3year': ['3year', 'lifetime', '5year'],
      '5year': ['3year', 'lifetime', '5year'],
    };
    let plans = promoId
      ? pricingPlans
      : pricingPlans.filter(
          (plan) =>
            3 === type ||
            (1 === type && !plan.one_time_purchase_product) ||
            (2 === type && plan.one_time_purchase_product)
        );
    if (2 === type) {
      const order = data?.plan_type ? sortMap[data.plan_type as keyof typeof sortMap] : null;
      if (order) {
        plans = plans.sort((plan1, plan2) => order.indexOf(plan1.type) - order.indexOf(plan2.type));
      }
    }
    return (
      <div
        className={`${classes.pageOtpCards} ${
          2 === type ? classes.pageOtpCardsOtp + ' ' + classes.pageOtpCardsSameHeight : ''
        }`}
      >
        <div className={classes.row}>
          {!!plans.length &&
            plans.map((plan, index) => (
              <Plan
                key={plan.id}
                featured={index === plans.length % 2}
                plan={plan}
                classes={classes}
                setSelected={handleSelectPlan}
              />
            ))}
        </div>
        {2 === type && (
          <div className={classes.pageOtpCardsOtpBenefits}>
            *All one-time purchase plans are eligible for all {ensureText('WeSalute+')} add-on benefits.
          </div>
        )}
      </div>
    );
  };
  const comparisonView = () => {
    const subPlans = pricingPlans
      .filter((plan) => !plan.one_time_purchase_product)
      .sort((plan1, plan2) => plan1.price.amount - plan2.price.amount);
    const otpPlans = pricingPlans
      .filter((plan) => plan.one_time_purchase_product)
      .sort((plan1, plan2) => plan1.price.amount - plan2.price.amount);
    return (
      <div className={classes.pageOtpCompareCards}>
        {!!subPlans.length && (
          <div className={`${classes.pageOtpCompareCardsGroup} ${classes.pageOtpCompareCardsGroupSubscription}`}>
            <div className={classes.pageOtpCompareCardsGroupHeader}>
              <div className={classes.pageOtpCompareCardsGroupHeaderTitle}>Subscriptions</div>
              <div className={classes.pageOtpCompareCardsGroupHeaderSubTitle}>
                Provides flexible options with automatic renewal or cancel anytime.
              </div>
            </div>
            <div className={classes.pageOtpCompareCardsGroupList}>
              {subPlans.map((plan) => {
                return (
                  <PlanListItem
                    key={plan.id}
                    featured={'year' === plan.type}
                    plan={plan}
                    classes={classes}
                    setSelected={handleSelectPlan}
                  />
                );
              })}
            </div>
          </div>
        )}
        {!!data && !!otpPlans.length && (
          <div className={`${classes.pageOtpCompareCardsGroup} ${classes.pageOtpCompareCardsGroupOtp}`}>
            <div className={classes.pageOtpCompareCardsGroupHeader}>
              <div className={classes.pageOtpCompareCardsGroupHeaderTitle}>One-Time Purchase</div>
              <div className={classes.pageOtpCompareCardsGroupHeaderSubTitle}>
                Offers additional savings for long term rewards.
              </div>
            </div>
            <div className={classes.pageOtpCompareCardsGroupList}>
              {otpPlans.map((plan) => {
                const { id, period } = plan;
                return (
                  <PlanListItem
                    key={id}
                    featured={3 === period.number}
                    plan={plan}
                    classes={classes}
                    setSelected={handleSelectPlan}
                  />
                );
              })}
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      {!showPromotionBlock &&
        !promoId &&
        !!data &&
        !['year', '3year', '5year', 'lifetime'].includes(data.plan_type) && (
          <div className={classes.pageOtpPlanFilters}>
            <div className={classes.pageOtpPlanFiltersBtns}>
              <Button
                color='primary'
                variant={activeFilter === 1 ? 'contained' : 'outlined'}
                className={`${classes.pageOtpPlanFiltersBtn} left`}
                onClick={() => {
                  setActiveFilter(1);
                }}
              >
                Subscription
              </Button>
              <Button
                color='primary'
                variant={activeFilter === 2 ? 'contained' : 'outlined'}
                className={`${classes.pageOtpPlanFiltersBtn} right`}
                onClick={() => {
                  setActiveFilter(2);
                }}
              >
                One-Time Purchase
              </Button>
            </div>
            <Button
              className={`${classes.pageOtpPlanFiltersBtnCompare} ${activeFilter === 3 ? 'active' : ''}`}
              onClick={() => {
                setActiveFilter(3);
              }}
            >
              Compare All Options
            </Button>
          </div>
        )}

      {3 !== activeFilter ? setPLanView(promotion, activeFilter) : comparisonView()}

      <div className={classes.pageOtpDivider}></div>

      {!showPromotionBlock && !!selectedPlan?.id && (
        <div className={classes.pageOtpSelection}>
          <div className={classes.pageOtpSelectionName}>
            You have selected a {selectedPlan.trial ? '30-Day Introductory Trial' : selectedPlan.title}{' '}
            {!selectedPlan.trial ? (selectedPlan.one_time_purchase_product ? 'Plan' : 'subscription') : ''}
          </div>
          <div className={classes.pageOtpSelectionInfo}>
            <div className={classes.pageOtpSelectionPrice}>
              <PlanPrice plan={selectedPlan} />
            </div>
            {'year' === selectedPlan.period.unit && !selectedPlan.trial && (
              <div
                className={classes.pageOtpSelectionFee}
                // {...(selectedPlan.type === 'lifetime' ? { style: { textDecoration: 'line-through' } } : {})}
              >
                +$4.95 processing fee
              </div>
            )}
          </div>
        </div>
      )}

      {!showPromotionBlock && (
        <div className={classes.pageOtpBenefits}>
          <div className={classes.pageOtpBenefitsTitle}>Choose your Add-on Benefits </div>
          {/* If no plan purchased yet OR if not OTP selected */}
          {data && !['year', '3year', '5year'].includes(data.plan_type) && (
            <div className={classes.pageOtpBenefitsInfo}>
              <div className={classes.pageOtpBenefitsInfoText}>
                {ensureText('WeSalute+')} add-on benefits are available with select plans.
              </div>
              <TooltipDialog
                element={({ toggle }) => (
                  <div className={classes.pageOtpBenefitsInfoIcon} onClick={() => toggle()}>
                    <InfoIcon fontSize='inherit' />
                  </div>
                )}
              >
                <div className={classes.accountTooltipContent}>
                  *Eligibility for add-on benefits:
                  <br />
                  <br />
                  You may add household members at 50% off to a 12 Month subscription, Month-to-Month subscription, and
                  all One-Time Purchase plans.
                  <br />
                  <br />
                  You may add Travel Protection to the 12 Month Subscription and all One-Time Purchase plans.
                </div>
              </TooltipDialog>
            </div>
          )}
          {!!data && !!selectedPlan && (
            <>
              {/* Stacking - OTP selected and OTP is a current plan. */}
              {['3year', '5year'].includes(data.plan_type) && ['3year', '5year'].includes(selectedPlan.type) && (
                <div>
                  *You have {expiresIn} remaining on your current plan. When you add NEW add-on benefits, we will charge
                  you a prorated term for selected benefits + the 3 or 5 year term you are stacking to ensure your
                  benefit terms are in sync.
                </div>
              )}
              {/* Stacking - Lifetime selected and Year is a current plan. */}
              {/* {'year' === data.plan_type && 'lifetime' === selectedPlan.type && (
                <div>
                  *When you enroll in a Lifetime WeSalute+ plan, you will automatically be credited for unused time on
                  your current plan. Add-on benefits will continue their existing annual billing cycle, and will receive
                  our greatest value pricing at $19.99 each year moving forward.
                </div>
              )} */}
              {/* Stacking - Lifetime selected and OTP is a current plan. */}
              {/* {['3year', '5year'].includes(data.plan_type) && 'lifetime' === selectedPlan.type && (
                <div>
                  *When you enroll in a Lifetime WeSalute+ plan, you will automatically be credited for unused time on
                  your current plan and for add-on benefits. Add-on benefits will be billed annually. Household member
                  plans will receive our greatest value pricing at $19.99/year.
                </div>
              )} */}
            </>
          )}
        </div>
      )}

      <div className={classes.plansOther}>
        <HouseholdMemberBlock handleOpenMembersDialog={handleOpenMembersDialog} />
        <TravelProtectionBlock
          requiredError={tpRequiredError}
          handleOpenTravelProtectionDialog={handleOpenTravelProtectionDialog}
        />
      </div>
      {!otpPurchased && (
        <div className={classes.actionsWrapper}>
          {tpRequiredError && <div className={classes.actionsError}>Please make a Travel Protection selection</div>}
          <Button
            // disabled={!tpDisabled && undefined === user.travel_protection}
            color='secondary'
            variant='contained'
            onClick={handleNext}
          >
            Continue to review your plan
          </Button>
        </div>
      )}
    </>
  );
}
