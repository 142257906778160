import React from 'react';
import { UseStyles } from 'hooks';
import { DialogDefault } from 'components/Dialog/DialogDefault';
import { useDialog } from 'hooks/useDialog';
import Button from '@mui/material/Button';
import { useStoreon } from 'storeon/react';
import { ensureText, ensureUrl } from 'utils';

export interface ComponentProps {
  name: string;
}

export function CancelPlanShortDialog({ name }: ComponentProps): JSX.Element {
  const classes = UseStyles();
  const { isOpen, close } = useDialog(name);
  const { dispatch } = useStoreon();
  return (
    <DialogDefault open={isOpen} onClose={close} title={'Cancel Plan'}>
      <div className={classes.accountDialogCancelPlanShort}>
        <div className={classes.accountDialogCancelPlanTitle}>
          We’re sad to see you go.
          <br />
          Did you know about these valuable benefits?
        </div>
        <div className={classes.accountDialogCancelPlanShortRow}>
          <div className={classes.accountDialogCancelPlanShortCol}>
            <div
              className={`${classes.accountDialogCancelPlanShortItem} ${classes.accountDialogCancelPlanShortItemActive}`}
            >
              <div className={classes.accountDialogCancelPlanShortItemTitle}>VIP Member Support</div>
              <div className={classes.accountDialogCancelPlanShortItemText}>
                Get live, friendly assistance redeeming exclusive offers and exploring your {ensureText('WeSalute+')}{' '}
                benefits and plan options.
              </div>
              <Button
                className={classes.accountDialogCancelPlanShortItemBtn}
                target='_blank'
                href={ensureUrl('https://www.wesalute.com/live-vip-member-support-vetrewards')}
                color='primary'
                variant='outlined'
              >
                Get Support
              </Button>
            </div>
          </div>
          <div className={classes.accountDialogCancelPlanShortCol}>
            <div className={classes.accountDialogCancelPlanShortItem}>
              <div className={classes.accountDialogCancelPlanShortItemTitle}>Custom Financial Planning</div>
              <div className={classes.accountDialogCancelPlanShortItemText}>
                Reach your financial goals and milestones.
                <ul>
                  <li>Will & Estate Planning</li>
                  <li>Retirement Planning</li>
                  <li>Personal Insurance Review</li>
                  <li>Investment Analysis</li>
                  <li>College Funding</li>
                  <li>And so much more...</li>
                </ul>
              </div>
              <Button
                className={classes.accountDialogCancelPlanShortItemLink}
                target='_blank'
                href={ensureUrl('https://www.wesalute.com/offers/vetrewards-carepackagetm-custom-financial-planning')}
              >
                Request an Appointment
              </Button>
            </div>
          </div>
          <div className={classes.accountDialogCancelPlanShortCol}>
            <div className={classes.accountDialogCancelPlanShortItem}>
              <div className={classes.accountDialogCancelPlanShortItemTitle}>Prescription Drug Discount</div>
              <div className={classes.accountDialogCancelPlanShortItemText}>
                Deeply discounted emergency insurance.
                <ul>
                  <li>Save up to 80% on prescriptions</li>
                  <li>
                    Save at over 65,000 pharmacies including Walgreens, Costco, Walmart, Target, ShopRite, Albertsons,
                    and many smaller neighborhood pharmacies.
                  </li>
                </ul>
              </div>
              <Button
                className={classes.accountDialogCancelPlanShortItemLink}
                target='_blank'
                href={ensureUrl('https://www.wesalute.com/prescription-drug-discount')}
              >
                Learn More about RX Discount
              </Button>
            </div>
          </div>
        </div>
        <Button
          className={classes.accountDialogCancelPlanCancel}
          onClick={() => {
            close();
            dispatch('dialog/open', { id: 'autorenewSubscription' });
          }}
        >
          I’d like to cancel anyway
        </Button>
      </div>
    </DialogDefault>
  );
}
