import React from 'react';
import { UseStyles } from 'hooks';
import { DialogDefault } from 'components/Dialog/DialogDefault';
import { useDialog } from 'hooks/useDialog';

export interface ComponentProps {
  name: string;
  title: string;
  subTitle?: string | React.ReactNode;
  description?: string | React.ReactNode;
  undismissible?: boolean;
}

export function FeedbackDialog({ name, title, subTitle, description, undismissible }: ComponentProps): JSX.Element {
  const classes = UseStyles();
  const { isOpen, close } = useDialog(name);
  return (
    <DialogDefault open={isOpen} onClose={!undismissible && close} title={title}>
      {!!subTitle && <div className={classes.accountDialogContentSubTitle}>{subTitle}</div>}
      {!!description && <div>{description}</div>}
    </DialogDefault>
  );
}
