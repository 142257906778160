import React from 'react';
import { ClassNameMap } from '@mui/styles/withStyles';
import { ensureText, getConvertedPrice } from 'utils';
import { DiscountPrice } from './DiscountPrice';
import { PurchaseBriefLineItemsGroupOutput } from 'services/api/client';
import { UseCheckoutDetails } from 'context/checkoutDetails.context';

type Props = {
  classes: ClassNameMap;
  user: PurchaseBriefLineItemsGroupOutput;
};

export function DiscountBlock({ classes, user }: Props): JSX.Element | null {
  const promoTitles = {
    '1year': '12 month discounted fee vs. monthly plan',
    '3year': '3 year discounted fee vs. monthly plan',
    '5year': '5 year discounted fee vs. monthly plan',
  };
  const {
    state: {
      config: { plan_extension_preselect_product },
      promoId,
      selectedPlan,
    },
  } = UseCheckoutDetails();
  if (!selectedPlan) return null;
  const { number, unit } = selectedPlan?.period ?? { number: 1, unit: 'year' };
  const multiplier = ('year' === unit ? 12 : 1) * number;
  const oldPrice = 999 * multiplier;
  const extSelected = !!plan_extension_preselect_product;
  const promoTitle = promoTitles[`${number}${unit}` as keyof typeof promoTitles];
  return (
    <>
      {user.items.map(({ title, price, type, discount_reason }) => {
        const saleType = !!promoId && ['plan', 'subscription_extension'].includes(type);
        const hideDiscountLine = !!promoId && ('subscription_extension' === type || ('plan' === type && !extSelected));
        const amount = price.discount && !hideDiscountLine ? price.cost : price.amount;
        return (
          <React.Fragment key={`${title} + ${type}`}>
            {(amount !== 0 || (!!promoId && !extSelected)) && (
              <div className={`${classes.reviewDetail}`}>
                {saleType && promoTitle ? (
                  <span>{promoTitle}</span>
                ) : (
                  <span>{'travel_protection' === type ? `${ensureText('WeSalute+')} Travel Protection` : title}</span>
                )}
                <div className={classes.membersOldPrice}>
                  {saleType && 'year' === selectedPlan.type && amount < oldPrice ? (
                    <span>${oldPrice / 100}</span>
                  ) : null}
                  {getConvertedPrice(amount)}
                </div>
              </div>
            )}
            {!hideDiscountLine && <DiscountPrice price={price} reason={discount_reason} type={type} />}
          </React.Fragment>
        );
      })}
    </>
  );
}
