import Head from 'next/head';
import React, { useEffect, useState } from 'react';
import router from 'next/router';
import { UseUserEnrollInfo } from 'api/userEnrollInfo';
import { UseCheckoutDetails } from 'context/checkoutDetails.context';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Typography from '@mui/material/Typography';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { MobileStepper, TabPanel } from 'components/Display';
import { TabPanelsWrapper } from 'components/TabPanelsWrapper';
import TravelProtectionDialog from 'dialog/TravelProtectionDialog';
import TrialDialog from 'dialog/TrialDialog';
import DonateDialog from 'dialog/DonateDialog';
import MembersDialog from 'dialog/MembersDialog';
import SupportDialog from 'dialog/SupportDialog';
import { UseStyles } from 'hooks';
import { useMemberApi } from 'hooks/useMemberApi';
import { MemberInfoOutput } from 'services/api/client';
import { ScrollToTop } from 'components/ScrollToTop';
import { Loader } from 'components/LoadingBlock';
import container from 'services/container';
import EventManager from 'services/EventManager';
import { ensureText, hostTld, pageTitle } from 'utils';
import { HelpChat } from 'components/HelpChat/HelpChat';
import { HeaderDefault } from '@components/Header/HeaderDefault';

export function AuthenticatedLayout(): JSX.Element {
  const classes = UseStyles();
  const appshell = container.get('appshell');
  const { enrollInfo, errorType, isValidating } = UseUserEnrollInfo();
  const { dispatch } = UseCheckoutDetails();
  const { data } = useMemberApi<MemberInfoOutput>('memberInfo');
  const isAndroid = window.navigator.userAgent.toLowerCase().indexOf('android') !== -1;
  const urlParams = new URLSearchParams(window.location.search);
  const [iframe, setIframe] = useState<boolean>(false);

  useEffect(() => {
    if (urlParams.has('iframe')) {
      setIframe(true);
    }
  }, []);

  useEffect(() => {
    dispatch({ type: 'S_ENROLMENT_STARTED' });
    EventManager.dispatch('page.viewed', 'Checkout');
  }, [dispatch]);

  useEffect(() => {
    if (!enrollInfo) return;
    const payload = {
      ...enrollInfo.user,
      card: enrollInfo.card,
      household_members: [
        ...enrollInfo.household_members.active.map((item) => ({ ...item, active: true })),
        ...enrollInfo.household_members.inactive,
      ].slice(0, 4),
    };
    dispatch({ type: 'SET_USER', payload });

    dispatch({
      type: 'SET_BRAND_QUEUE',
      payload: enrollInfo.brand_queue,
    });

    dispatch({
      type: 'SET_CONFIG',
      payload: enrollInfo.config,
    });
  }, [enrollInfo?.card, enrollInfo?.config, dispatch]);

  const [activeStep, setActiveStep] = useState(0);
  const [openTravelProtectionDialog, setOpenTravelProtectionDialog] = useState(false);
  const [openTrialDialog, setOpenTrialDialog] = useState(false);
  const [openDonateDialog, setOpenDonateDialog] = useState(false);
  const [openMembersDialog, setOpenMembersDialog] = useState(false);

  useEffect(() => {
    const user = appshell?.user ?? {};
    if (user.email && user.data) {
      window.UserLeap('setUserId', user.data.memberId);
      window.UserLeap('setEmail', user.email);
    }
  }, [appshell?.user]);

  useEffect(() => {
    if (data?.id) {
      const returnTo = urlParams.has('return_to') ? decodeURIComponent(urlParams.get('return_to')!) : null;
      const isCheckoutApp = /^checkout(-[a-z]+)?\.wesalute.com/.test(window.location.hostname);
      const returnToOfferPage = returnTo && /\.wesalute.com\/offers\//.test(returnTo);
      if (data.plan_expires && isCheckoutApp && returnToOfferPage) {
        window.location.href = returnTo;
      }
    }
  }, [data?.id]);

  useEffect(() => {
    if (data?.travel_protection && !data.plan_expires) {
      if (/^checkout(-[a-z]+)?\.wesalute.com/.test(window.location.hostname)) {
        window.location.href = `${process.env.NEXT_PUBLIC_CHECKOUT_APP_URL}/wesalute-plus`;
      }
    }
  }, [data?.travel_protection]);

  useEffect(() => {
    const beforeUnloadListener = (e: Event) => {
      e.preventDefault();
      return (e.returnValue = true);
    };
    if (activeStep < 3) {
      window.addEventListener('beforeunload', beforeUnloadListener);
    }

    return () => {
      window.removeEventListener('beforeunload', beforeUnloadListener);
    };
  }, [activeStep]);

  const steps = ['Select Plan', 'Review Details', 'Complete Information'];

  const handleOpenTravelProtectionDialog = (): void => {
    setOpenTravelProtectionDialog(true);
  };

  const handleCloseTravelProtectionDialog = (): void => {
    setOpenTravelProtectionDialog(false);
  };

  const handleOpenTrialDialog = (): void => {
    setOpenTrialDialog(true);
  };

  const handleCloseTrialDialog = (): void => {
    setOpenTrialDialog(false);
  };

  const handleOpenDonateDialog = () => {
    setOpenDonateDialog(true);
  };

  const handleCloseDonateDialog = () => {
    setOpenDonateDialog(false);
  };

  const handleOpenMembersDialog = () => {
    setOpenMembersDialog(true);

    // Android popup scroll fix.
    if (isAndroid) {
      window.scrollTo(0, 0);
    }
  };

  const handleCloseMembersDialog = () => {
    setOpenMembersDialog(false);
  };

  const handleButtonNextStep = (): void => {
    setActiveStep((prevState) => {
      dispatch({ type: 'S_STEP_COMPLETED', payload: { title: steps[prevState], step: prevState + 1 } });
      return prevState + 1;
    });
  };

  const handleButtonPrevStep = (): void => {
    setActiveStep(activeStep - 1);
  };

  const renderPageTitle = (): string | undefined => {
    switch (activeStep) {
      case 0:
        return router.router?.route.startsWith('/account')
          ? `${ensureText('WeSalute+')} on your terms`
          : `Enhance Your Lifestyle With ${ensureText('WeSalute+')} Exclusives`;
      case 1:
        return 'Here are your plan details';
      case 2:
        return 'Almost done!';
      case 3:
        return 'Welcome to WeSalute+, ' + data?.first_name;
      default:
        break;
    }
  };

  const exit = (defaultPath: string) => () => {
    const appshell = container.get('appshell');
    if (urlParams.has('return_to')) {
      window.location.href = decodeURIComponent(urlParams.get('return_to')!);
      return;
    }
    const referrer = document.referrer;
    if (referrer) {
      const url = new URL(referrer);
      const domain = url.hostname;
      if (domain.includes(`.${hostTld()}`) && !domain.includes('auth')) {
        window.location.href = referrer;
        return;
      }
    }
    if (appshell && appshell.goto) {
      appshell.goto(defaultPath);
    }
  };

  return (
    <>
      <Head>
        <title>{pageTitle('Checkout')}</title>
      </Head>
      <ScrollToTop activeStep={activeStep} />
      <HeaderDefault />
      <div className={classes.pageContentWrapper}>
        {!iframe && (
          <Button
            className={`${classes.exit}
            ${classes.topHelperBtn}
            ${classes.noPrint}`}
            onClick={exit('marketing/marketplace')}
          >
            <CloseIcon className='icon' />
            Exit
          </Button>
        )}
        {activeStep && activeStep < 3 ? (
          <Button className={`${classes.back} ${classes.topHelperBtn}`} onClick={handleButtonPrevStep}>
            <ArrowBackIosIcon className='icon' />
            Back
          </Button>
        ) : null}
        <div className={classes.pageContent}>
          <Typography className={classes.pageTitle} component='h1' variant='h1'>
            {renderPageTitle()}
          </Typography>
          {data?.plan_type && !['trial', 'month'].includes(data.plan_type) && activeStep < 3 && (
            <div className={classes.pageOtpSubTitle}>
              {'year' === data.plan_type && <>Upgrade to a One Time Purchase plan for instant savings!</>}
            </div>
          )}
          {!isValidating && !errorType ? (
            <>
              {activeStep < 3 && (
                <>
                  <Stepper activeStep={activeStep} alternativeLabel className={classes.stepper}>
                    {steps.map((label) => (
                      <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                  <MobileStepper activeStep={activeStep} steps={steps} className={classes.mobileStepper} />
                </>
              )}
              <TabPanel index={activeStep} value={activeStep}>
                <TabPanelsWrapper
                  classes={classes}
                  plans={enrollInfo.plans}
                  ranks={enrollInfo.ranks}
                  branches={enrollInfo.branches}
                  promotion={enrollInfo.promotion}
                  activeStep={activeStep}
                  handleButtonNextStep={handleButtonNextStep}
                  handleButtonPrevStep={handleButtonPrevStep}
                  handleOpenTravelProtectionDialog={handleOpenTravelProtectionDialog}
                  handleOpenMembersDialog={handleOpenMembersDialog}
                  handleOpenDonateDialog={handleOpenDonateDialog}
                  handleOpenTrialDialog={handleOpenTrialDialog}
                />
              </TabPanel>
            </>
          ) : (
            <Loader />
          )}
        </div>
      </div>
      <TravelProtectionDialog open={openTravelProtectionDialog} onClose={handleCloseTravelProtectionDialog} />
      <TrialDialog
        open={openTrialDialog}
        onClose={handleCloseTrialDialog}
        handleButtonNextStep={handleButtonNextStep}
      />
      <DonateDialog open={openDonateDialog} onClose={handleCloseDonateDialog} />
      <SupportDialog
        open={!!errorType && ['internal.user.corrupted', 'access.user.subscription'].includes(errorType)}
      />
      {openMembersDialog && <MembersDialog open={openMembersDialog} onClose={handleCloseMembersDialog} />}
      <HelpChat />
    </>
  );
}
