import React, { useState } from 'react';
import Head from 'next/head';
import { pageTitle } from 'utils';
import { UseStyles } from 'hooks';
import { HeaderDefault } from '@components/Header/HeaderDefault';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import { EventManager } from 'services';
import { Kustomer } from 'containers/Widget/Kustomer';
import { FeedbackDialog } from 'containers/Dialog/FeedbackDialog';
import { useStoreon } from 'storeon/react';
import { ensureUrl } from 'utils';

export function AuthenticatedHHMLayout(): JSX.Element {
  const classes = UseStyles();
  const { dispatch } = useStoreon();
  const [submitting, setSubmitting] = useState(false);
  const notifyButtonLabel = 'Yes, notify me';

  const notify = (): void => {
    dispatch('dialog/open', { id: 'notifyMe' });
    EventManager.dispatch('cta.clicked', {
      action: 'notify-wesalute-upgrade',
      label: notifyButtonLabel,
      type: 'button',
      url: window.location.href,
    });
    setSubmitting(true);
  };

  const close = (): void => {
    const referrer = document.referrer;
    const currentUrl = document.location.href;
    if (!referrer || referrer === currentUrl) {
      document.location.href = `${ensureUrl(process.env.NEXT_PUBLIC_ACCOUNT_APP_URL!)}/profile`;
    } else {
      history.back();
    }
  };

  return (
    <>
      <Head>
        <title>{pageTitle('Household Member Gating')}</title>
      </Head>
      <HeaderDefault hideUserMenu />
      <Button className={classes.hhmGatingHeaderClose} onClick={close}>
        <CloseIcon fontSize='inherit' />
      </Button>
      <div className={classes.pageContentWrapper}>
        <div className={classes.pageContent}>
          <div className={classes.hhmGating}>
            <div className={classes.hhmGatingTitle}>
              Upgrading to WeSalute+ is temporarily unavailable for your account.
            </div>
            <div className={classes.hhmGatingDescription}>
              Our team is working to resolve this issue.
              <br />
              Would you like to be notified as soon as we are able to upgrade your account?
            </div>
            <div className={classes.hhmGatingBtns}>
              <Button disabled={submitting} color='primary' variant='contained' size='small' onClick={notify}>
                {notifyButtonLabel}
              </Button>
              <Button color='primary' variant='outlined' size='small' onClick={close}>
                Close
              </Button>
            </div>
            <Kustomer buttonText='Contact Customer Support' />
            <FeedbackDialog
              name='notifyMe'
              title='Get WeSalute+'
              subTitle='Your request has been saved.'
              description='We will notify you as soon as you are able to upgrade your plan.'
            />
          </div>
        </div>
      </div>
    </>
  );
}
